import { AutobizNavBar } from 'autobiz-strap';
import { t } from 'autobiz-translate';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';

import { useAuth0 } from '@auth0/auth0-react';
import {
    OfficeCountry,
    OfficeLanguage,
    appDefaultLang,
    officeLanguages,
} from '../../../../hexagon/config/appConfig';
import { getContactEmail, getFlag } from '../../../../language-context';
import { getUserFeedsSelector } from '../../view-models-generators/userFeedsSelectors';
import { getUserSelector } from '../../view-models-generators/userSelector';
import { setUserLangUseCase } from '../../../../hexagon/usecases/setUserLang/setUserLangUseCase';

const Header: FunctionComponent = () => {
    const { logout } = useAuth0();
    const dispatch = useDispatch();

    const { user: userData } = useSelector(getUserSelector);

    const country: OfficeCountry = userData.country?.toLowerCase() as OfficeCountry;

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const { count: userFeedsCount, data: userFeedsData } = useSelector(getUserFeedsSelector);

    const onChangeLang = (id: string) => {
        dispatch(setUserLangUseCase(id));
    };

    const language = userData.language || (appDefaultLang as OfficeLanguage);

    const ref = useRef<any>();

    const LanguageSwitcher = () => (
        <div ref={ref}>
            <Row className="w-100 m-0 p-0 ">
                <Col className="title col-8 m-0 p-0">{t('languages')}</Col>
                <Col className="col-4 m-0 p-0 text-right">
                    <img src={getFlag(language)} alt={language} className="icon-flag mr-2" />
                    {language.toUpperCase()}
                </Col>
                <div className={`menu-container ${isOpen ? 'open' : ''}`}>
                    <ul>
                        {officeLanguages.map(({ id, language_iso2 }) => (
                            <li
                                key={id}
                                className="pointer changeLang"
                                id={`change_lang_${language_iso2}`}
                                data-lang={language_iso2}
                            >
                                <a role="button" onClick={() => onChangeLang(id)}>
                                    <img
                                        src={getFlag(language_iso2 as OfficeLanguage)}
                                        alt={language_iso2}
                                        className="icon-flag mr-2"
                                    />

                                    {language_iso2.toUpperCase()}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </Row>
        </div>
    );
    const contractNameSwitcher = (provider: string) => (
        <div className="contractNameSwitcher">
            <strong>{t('contractName')}</strong> : {provider === 'AZ' && t('autobiz_contract')}
            {provider === 'STLA' && t('stla_contract')}
            {provider === 'SMART' && t('smart_contract')}
        </div>
    );

    const apps = userData.userApps?.filter((p: any) => p.name !== 'autobizOffice');

    const help = [
        {
            name: t('help_support') || '',
            onClick: () => {
                window.open(t(`help_support_link`), '_blank');
            },
        },
        {
            name: t('contact_us') || '',
            onClick: () => {
                window.location.href = `mailto:${getContactEmail(country)}`;
            },
        },
    ];

    const providerHelp = {
        SMART: [],
        STLA: [
            {
                name: t('terms_and_conditions_nrm') || '',
                onClick: () => {
                    window.open(t('terms_and_conditions_nrm_link'), '_blank');
                },
            },
        ],
        AZ: [
            {
                name: t('terms_and_conditions_az') || '',
                onClick: () => {
                    window.open(t('terms_and_conditions_az_link'), '_blank');
                },
            },
            {
                name: t('terms_and_conditions_sale_az') || '',
                onClick: () => {
                    window.open(t('terms_and_conditions_sale_az_link'), '_blank');
                },
            },
        ],
    };

    if (userData.provider && providerHelp[userData.provider]) {
        help.push(...providerHelp[userData.provider]);
    }

    const parameters = [
        {
            name: t('tradeInSettingsTitle'),
            onClick: () => navigate('/preferences'),
        },
    ];

    const languages = [
        {
            name: LanguageSwitcher() as any,
            onClick: () => setIsOpen(!isOpen),
        },
    ];
    const userMenu = [
        {
            name:
                userData?.provider &&
                (contractNameSwitcher(userData.provider.toUpperCase()) as any),
        },
    ];

    if (['STLA'].includes(userData.provider)) {
        languages.unshift({
            name: t('profileLink'),
            onClick: () => navigate('/userprofile'),
        });
    }

    let menu: any = {
        help,
        apps,
        languages,
        userMenu,
        user: {
            firstName: userData.firstName,
            lastName: userData.lastName,
            country: userData.country,
            email: userData.email,
            logout: async () => {
                await logout({
                    logoutParams: { federated: true, returnTo: window.location.origin },
                });
            },
        },
    };

    if (userData.canEditPreferences) {
        menu = {
            ...menu,
            parameters,
        };
    }

    if (userFeedsData) {
        const notifications = {
            count: userFeedsCount,
        };
        if (Object.keys(userFeedsData).length > 0) menu.notifications = notifications;
    }

    return (
        <AutobizNavBar
            brandLink="./dashboard"
            lang={language}
            maxWidth={1140}
            brand="autobizOffice"
            menu={menu}
        />
    );
};
export default Header;
