import React, { useEffect, useState } from 'react';
import { t } from 'autobiz-translate';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, ModalFooter } from 'reactstrap';
import Cookies from 'js-cookie';
import { marketUrl } from '../../../../config';

const daysName = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const broadcastDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const broadcastCountry = ['FR'];
const broadcastProvider = ['AZ'];

const startDate = new Date('2025-03-17');
const endDate = new Date('2025-04-11');

const PopUpMarket = ({ country, provider }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const now = new Date();
    const todayDayName = daysName[now.getDay()];
    const userCountry = country?.toUpperCase();
    const contentPopUpMarket = t('contentPopUpMarketV2') || '';

    // Helper function to check if the user has seen the popup today
    const hasAlreadySeenPopupToday = () =>
        Cookies.get('popUpMarketLastSeen') === now.toDateString();

    // Condition to determine if the popup should display
    const shouldDisplayPopup =
        broadcastDays.includes(todayDayName) &&
        broadcastCountry.includes(userCountry) &&
        broadcastProvider.includes(provider) &&
        now >= startDate &&
        now <= endDate &&
        hasAlreadySeenPopupToday() === false;

    const getCurrentDomain = () => {
        if (window.location.port.length > 0) {
            return window.location.hostname;
        }
        return `.${window.location.hostname.match(/\w*\.\w*$/gi)[0]}`;
    };

    // Show modal if the condition is met
    useEffect(() => {
        if (shouldDisplayPopup) {
            setModalOpen(true);
        }
    }, [shouldDisplayPopup]);

    useEffect(() => {
        if (modalOpen) {
            Cookies.set('popUpMarketLastSeen', now.toDateString(), { domain: getCurrentDomain() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen]);

    return (
        <Modal
            contentClassName="p-4"
            isOpen={modalOpen}
            toggle={() => setModalOpen(!modalOpen)}
            centered="true"
        >
            <ModalHeader className="text-center d-block border-0">
                {t('titlePopUpMarketV2')}
            </ModalHeader>
            <ModalBody
                dangerouslySetInnerHTML={{
                    __html: contentPopUpMarket,
                }}
            />
            <ModalFooter className="mt-2 border-0">
                <Row className="w-100">
                    <Col className="p-0 pr-2">
                        <Button
                            type="button"
                            color="primary"
                            outline
                            block
                            onClick={() => setModalOpen(!modalOpen)}
                            className="d-block h-100"
                        >
                            {t('closePopUpMarketV2')}
                        </Button>
                    </Col>
                    <Col className="p-0">
                        <Button
                            type="button"
                            color="primary"
                            block
                            onClick={() => {
                                window.open(marketUrl, '_blank');
                                setModalOpen(!modalOpen);
                            }}
                            className="d-block h-100"
                        >
                            {t('openPopUpMarketV2')}
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
};

export default PopUpMarket;
